/* eslint-disable */
import { ref, watch, computed } from "@vue/composition-api";
export default function useaccountsList() {
  const refaccountListTable = ref(null);

  const perPage = ref(10);
  const totalaccounts = ref(0);
  const currentPage = ref(1);

  const dataMeta = computed(() => {
    const localItemsCount = refaccountListTable.value
      ? refaccountListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalaccounts.value,
    };
  });

  return {
    perPage,
    currentPage,
    totalaccounts,
    dataMeta,
    refaccountListTable,
  };
}
