<template>
  <b-sidebar
    id="add-new-account-sidebar"
    :visible="isAddNewAccountSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-account-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("message.accounts.newAccount") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewaccount)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="descrição"
            rules="required"
          >
            <b-form-group
              label="Descrição"
              label-for="title"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.accounts.desc") }}
              </template>
              <quill-editor
                id="title"
                style="margin-bottom: 82px; height: 300px"
                :options="editorOption"
                v-model="accountData.description"
              />
            </b-form-group>
          </validation-provider>

          <!-- Date -->
          <validation-provider
            #default="validationContext"
            name="date"
            rules="required"
          >
            <b-form-group label-for="date">
              <template v-slot:label>
                {{ $t("message.accounts.date") }}
              </template>
              <flat-pickr
                v-model="accountData.date"
                :config="flatConfig"
                class="form-control"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template>
                  {{ $t("message.list.fieldRequired") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Total Value -->
          <validation-provider
            #default="validationContext"
            name="currency"
            rules="required"
          >
            <b-form-group
              label-for="currency"
              label="currency"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.accounts.valor") }}
              </template>
              <b-form-input
                id="currency"
                v-model="accountData.value"
                v-money="getCurrencyFormat()"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="category"
            rules="required"
          >
            <b-form-group
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.accounts.category") }}
              </template>
              <v-select
                v-model="accountData.category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categoryOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="category"
              />
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="tipo"
            rules="required"
          >
            <b-form-group
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.accounts.bankAccount") }}
              </template>
              <v-select
                v-model="accountData.bankAccount"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="bankOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="account-role"
              />
            </b-form-group>
          </validation-provider>

          <b-form-group label-for="expense">
            <template v-slot:label>
              {{ $t("message.accounts.createExpense") }}
            </template>
            <b-form-checkbox
              v-model="createExpense"
              name="is-vertical-menu-collapsed"
              :disabled="!createExpenseButton"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import date from "@/@core/utils/date";
import dateInput from "@/@core/utils/dateInput";
import store from "@/store";
import currency from "@/@core/utils/currency";
import { VMoney } from "v-money";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  model: {
    prop: "isAddNewAccountSidebarActive",
    event: "update:is-add-new-account-sidebar-active",
  },
  props: {
    isAddNewAccountSidebarActive: {
      type: Boolean,
      required: true,
    },
    categoryOptions: {
      type: Array,
      required: true,
    },
    bankOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      activeDate: false,
      activeDay: false,
      flatConfig: {
        dateFormat: "d/m/Y",
      },
      flatConfigDay: {
        dateFormat: "j",
      },

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      createExpense: false,
      createExpenseButton: true,

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,

      disabledButton: false,
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInput(newVal);
    },
    "accountData.value": function (newVal, oldVal) {
      if (newVal) {
        if (this.$i18n.locale === "en") {
          newVal = Number(newVal.replace(/[^0-9\.-]+/g, "")).toFixed(2);
        } else {
          newVal = this.switchDotsAndCommas(newVal);
          newVal = Number(newVal.replace(/[^0-9\.-]+/g, "")).toFixed(2);
        }

        // se não tem saldo, desabilita o botão criar despesa
        // if (newVal < 0) {
        //   this.createExpenseButton = true;
        // } else {
        //   this.createExpenseButton = false;
        // }
      }
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInput(this.$i18n.locale);
    }
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },

    async createExpenseObj(data) {
      var expenseObj = [
        {
          description: data.description,
          value: Math.abs(data.value),
          type: data.category,
          date: data.date,
          paymentType: "Cartão",
        },
      ];

      axios({
        method: "post",
        url: `${URL_API}expense`,
        headers: getHeader(this.userData),
        data: expenseObj[0],
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.create"),
            icon: "CheckIcon",
            variant: "success",
            text: `Despesa criada com sucesso`,
          },
        });
      });
    },

    async submitNewaccount() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.disabledButton = true;
          this.onSubmit();

          if (this.accountData.date != null) {
            this.accountData.date = date(
              this.$i18n.locale,
              this.accountData.date
            );
          }

          if (this.$i18n.locale === "en") {
            this.accountData.value = Number(
              this.accountData.value.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);
          } else {
            this.accountData.value = this.switchDotsAndCommas(
              this.accountData.value
            );
            this.accountData.value = Number(
              this.accountData.value.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);
          }

          if (this.createExpense) {
            this.createExpenseObj(this.accountData);
          }

          axios({
            method: "post",
            url: `${URL_API}account`,
            headers: getHeader(this.userData),
            data: this.accountData,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("message.toast.create"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: `Conta criada com sucesso`,
                },
              });
            })
            .then(() => {
              this.$root.$emit("newListAccount", null);
              this.disabledButton = false;
            })
            .catch((error) => {
              this.disabledButton = false;
              console.log(error);
            });
        }
      });
    },
  },

  setup(props, { emit }) {
    const blankaccountData = {};

    const accountData = ref(JSON.parse(JSON.stringify(blankaccountData)));
    const resetaccountData = () => {
      accountData.value = JSON.parse(JSON.stringify(blankaccountData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-account-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetaccountData);

    return {
      accountData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-account-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
